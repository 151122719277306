import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
let matchMedia = gsap.matchMedia();

document.addEventListener('DOMContentLoaded', () => {
	const sections = document.querySelectorAll('section.logos');

	sections.forEach((section) => {
		const logos = section.querySelectorAll('picture');

		matchMedia.add('(min-width:800px)', () => {
			gsap.from(logos, {
				opacity: 0,
				rotate: '-15deg',
				stagger: 0.2,
				scrollTrigger: {
					trigger: section,
					start: 'top center',
					end: 'bottom bottom',
				}
			});
		});
	});
});

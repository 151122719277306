import { gsap } from 'gsap';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
let matchMedia = gsap.matchMedia();

document.addEventListener('DOMContentLoaded', () => {
	const playButton = document.querySelector('.play-button');

	if (playButton) {
		const lightbox = GLightbox({
			selector: '.play-button',
			autoplayVideos: true,
		});
	}

	const sections = document.querySelectorAll('section.screenshots');
	sections.forEach(section => {
		matchMedia.add('(min-width: 769px)', () => {
			const desktopScreenshot = section.querySelector('.desktop-screenshot .frame-inner');
	
			gsap.to(desktopScreenshot, {
				yPercent: -100,
				y: '402px',
				scrollTrigger: {
					trigger: section,
					start: "top top",
					end: "+=800",
					scrub: true,
					pin: true,
					anticipatePin: 1,
				}
			})
		});

		matchMedia.add('(max-width: 768px)', () => {
			const mobileScreenshot = section.querySelector('.mobile-screenshot .frame-inner');
	
			gsap.to(mobileScreenshot, {
				yPercent: -100,
				y: '692px',
				scrollTrigger: {
					trigger: section,
					start: "top top",
					end: "+=800",
					scrub: true,
					pin: true,
					anticipatePin: 1,
				}
			})
		});
	});
});

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

document.addEventListener('scroll', () => {
	let top = window.scrollY;
	
	if ( top < 80 ) {
		document.body.classList.remove('scroll');
	} else if (!document.body.classList.contains('scroll')) {
		document.body.classList.add('scroll');
	}
});

document.addEventListener('DOMContentLoaded', () => {
	const controlVideoHeader = document.querySelectorAll('.control-button div');

	if (controlVideoHeader) {
		controlVideoHeader.forEach((control) => {
			const video = control.closest('section').querySelector('video');
			control.addEventListener('click', () => {
				if (video.paused) {
					video.play();
				} else {
					video.pause();
				}

				controlVideoHeader.forEach((control) => {
					control.classList.toggle('hidden');
				});
			});
		});
	}
});

document.addEventListener('DOMContentLoaded', () => {
	const headercontrols = document.querySelectorAll('header .phone-link i, header .email-link i, header .offcanvas-toggler');
	
	gsap.to(headercontrols, {
		scrollTrigger: {
			trigger: '.contrast-menu-controls',
			start: 'top top',
			end: 'bottom top',
			toggleClass: {targets: headercontrols, className: 'menu-white'}
		}
	});
});

 